<template>
  <session-layout>
    <template slot="overlay-loading">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
    <template slot="session-alert">
      <v-alert
      v-if="accountNotVerified"
        border="bottom"
        colored-border
        type="warning"
      >
        <span>Account not verified. You can <a class="underlined-link" @click="resendVerificationEmail">Resend</a> the verification email.</span>
      </v-alert>
    </template>
    <template slot="session-title">
      <h3>Sign in to your account</h3>
    </template>
    <template slot="session-body">
      <v-form v-model="valid" class="">
        <v-text-field
                class="mb-3"
                hint="Email"
                v-model="email"
                required
                persistent-hint
                :rules="emailRules"
                validate-on-blur>
        </v-text-field>
        <v-text-field
                hint="Password"
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                @click:append="showPassword = !showPassword"
                validate-on-blur
                persistent-hint
                v-on:keyup.enter="submit">
        </v-text-field>
      </v-form>
      <a class="d-block text-right remember" @click="$router.push('/session/reset-password')">Forgot password?</a>
      <v-btn
              depressed
              :disabled="$store.state.auth.loading"
              class="mt-10"
              style="width: 100%"
              @click="submit">
        Log in
        <v-progress-circular
                v-if="$store.state.auth.loading"
                style="position: absolute; right: 30%;"
                indeterminate
                :size="22"
                color="#fff"
        ></v-progress-circular>
      </v-btn>

      <div class="mt-5 text-center">
        <span class="fs-13 dark-grey-text">Need an Account?</span> <a class="text-right remember fs-13 " @click="$router.push('/session/signup')">Create an account</a>
      </div>
    </template>
  </session-layout>
</template>

<script>
  import SessionLayout from './Layout'
  import {mapGetters,mapState} from 'vuex'
  export default {
    name: 'Login',
    computed: {
      ...mapGetters(['user']),
      ...mapState(['loading'])
    },
    data() {
      return {
        valid: false,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: '',
        passwordRules: {
          required: value => !!value || 'Password is required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
        showPassword: false,
        accountNotVerified: false,
        overlay: false,
      }
    },
    mounted() {
      this.$store.commit('logoutUser', user)
    },
    methods: {
      async submit() {
        const hashPassword = btoa(this.password);
        const user = {
          email: this.email,
          password: hashPassword,
          redirect: '/home',
          refresh: true,
          host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
        }
        const response = await this.$store.dispatch('signInUserApi', user)
        if(response.status == 403) {
          this.accountNotVerified = true
        }
      },
      async resendVerificationEmail() {
        this.overlay = true
        const payload = {
          email: this.email
        }
        const response = await this.$store.dispatch('resendVerifyEmail', payload)
        console.log(response)
        this.overlay = false
      }
    },
    components: {
      SessionLayout
    }
  }
</script>
